import React from "react";

const TabNav = ({ data, tabs, activeTab, onTabChange }) => {
  return (
    <ul className="nav">
      {data &&
        tabs.map((tab) => (
          <li
            key={tab}
            className={
              "ui-tabs-tab ui-corner-top ui-state-default ui-tab" +
              (tab === activeTab ? ` ui-tabs-active ui-state-active` : "")
            }
          >
            <a href="#" onClick={(e) => onTabChange(e, tab)}>
              {tab}
            </a>
          </li>
        ))}
    </ul>
  );
};

export default TabNav;
