import React, { useEffect, useState } from "react";
import { useFaucets } from "../hooks/useFaucets";
import { useSnapshots } from "../hooks/useSnapshots";
import shuffleArray from "../utils/shuffleArr";

const Main = () => {
  const { faucets } = useFaucets();
  const { snapshots } = useSnapshots();
  const [flatSnapshots, setFlatSnapshots] = useState(null);
  const [flatFaucets, setFlatFaucets] = useState(null);

  const addFaucetsAvailability = (faucets) => {
    faucets.forEach((link) => {
      const response = fetch(link.endpoint, {
        method: "HEAD",
      });
      response
        .then((data) => {
          if (data.status) {
            if (data.status !== 502) {
              link.status = "Active";
            } else {
              link.status = "Inactive";
            }
          }
        })
        .catch(() => {
          link.status = "Inactive";
        })
        .finally(() => setFlatFaucets([...faucets]));
    });
  };

  function flattenObject(obj) {
    let flattenedArr = [];
    if (obj) {
      Object.keys(obj).forEach((tab) => {
        if (tab.toLowerCase() !== "rpc & state sync")
          obj[tab].forEach((link) => {
            link.network = tab;
            flattenedArr.push(link);
          });
      });
    } else {
      return null;
    }
    if (flattenedArr.length > 10) {
      shuffleArray(flattenedArr);
      flattenedArr.length = 10;
    }
    return flattenedArr;
  }

  useEffect(() => {
    if (Object.keys(snapshots).length > 0) {
      let flatSnapshots = flattenObject(snapshots);
      setFlatSnapshots(flatSnapshots);
    }
  }, [snapshots]);

  useEffect(() => {
    if (Object.keys(faucets).length > 0) {
      const flatFaucetsData = flattenObject(faucets);
      addFaucetsAvailability(flatFaucetsData);
    }
  }, [faucets]);

  return (
    <div className="column">
      <div className="column-01">
        <h2>Faucet status</h2>
        {flatFaucets &&
          flatFaucets.length > 0 &&
          flatFaucets.map((link, i) => (
            <dl key={`${link.name}-${i}`}>
              <dt>{link.name}</dt>
              <dd>{link.status}</dd>
            </dl>
          ))}
      </div>
      <div className="column-02">
        <h2>Snapshot status</h2>
        {flatSnapshots &&
          flatSnapshots.map((link, i) => {
            if (link.timestamp) {
              return (
                <dl key={`${link.name}-${i}`}>
                  <dt>{link.name}</dt>
                  <dd>{link.timestamp}</dd>
                </dl>
              );
            }
          })}
      </div>
    </div>
  );
};

export default Main;
