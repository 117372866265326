import React, { useEffect, useState } from "react";
import FaucetsTab from "../components/FaucetsTab";
import TabNav from "../components/TabNav";
import { useFaucets} from "../hooks/useFaucets";

function Faucets() {
  const [activeTab, setActiveTab] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [inputData, setInputData] = useState(null);
  const [fauParams, setFauParams] = useState(null);

  const { faucets } = useFaucets();
  const { faucetsParams } = useFaucets();

  useEffect(() => {
    if (faucets) {
      const tabs = Object.keys(faucets);
      setTabs(tabs);
      setActiveTab(tabs[0]);

      const initialInputData = {};
      tabs.forEach((tab) => {
        initialInputData[tab] = {};
        faucets[tab].forEach((link) => {
          initialInputData[tab][link.name] = "";
        });
      });
      setInputData(initialInputData);
    }
  }, [faucets]);

  useEffect(() => {
    if (faucetsParams) {
      setFauParams(faucetsParams);
    }
  }, [faucetsParams]);

  const handleInputChange = (tabName, linkName, value) => {
    setInputData((prevState) => ({
      ...prevState,
      [tabName]: { ...prevState[tabName], [linkName]: value },
    }));
  };

  const handleTabChange = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab);
    const formBlock = document.querySelector(".form-block.show");
    if (formBlock) {
      formBlock.classList.remove("show");
    }
  };

  return (
    <>
      <h1>FAUCETS</h1>
      <div id="tabs">
        <TabNav
          data={faucets}
          tabs={tabs}
          activeTab={activeTab}
          onTabChange={handleTabChange}
        />

        {faucets && inputData && (
          <FaucetsTab
            links={faucets[activeTab]}
            tabName={activeTab}
            onChange={handleInputChange}
            inputData={inputData}
            fauParams={fauParams}
          />
        )}
      </div>
    </>
  );
}

export default Faucets;
