import React, { useEffect, useState } from "react";
import BotsTab from "../components/BotsTab";
import TabNav from "../components/TabNav";
import botsService from "../services/bots.service";

function Bots() {
  const [activeTab, setActiveTab] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [bots, setBots] = useState(null);

  const getBotsData = async () => {
    try {
      const data = await botsService.get();
      setBots(data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getBotsData();
  }, [])

  useEffect(() => {
    if (bots) {
      const tabs = Object.keys(bots);
      setTabs(tabs);
      setActiveTab(tabs[0]);
    }
  }, [bots]);

  const handleTabChange = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab);
  };

  return (
    <>
      <h1>BOTS</h1>
      <div id="tabs">
        <TabNav
          data={bots}
          tabs={tabs}
          activeTab={activeTab}
          onTabChange={handleTabChange}
        />

        {bots && <BotsTab links={bots[activeTab]} />}
      </div>
    </>
  );
}

export default Bots;
