import React from "react";
import showdown from "showdown";
import swalmain from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const swal = withReactContent(swalmain);

function Tab({ tabName, links }) {
  const isRpc = tabName === "rpc & state sync";

  const handleStakingClick = (event, staking_info, staking_url) => {
    event.preventDefault();
    if (staking_info === undefined) {
      return;
    }
    const converter = new showdown.Converter();
    const html = converter.makeHtml(staking_info);
    swal
      .fire({
        html,
        showCloseButton: true,
        confirmButtonText: "Stake",
        customClass: {
          confirmButton: "popup-confirm",
          closeButton: "popup-close",
        },
        backdrop: "rgba(74, 114, 255, 0.4)",
      })
      .then(
        (value) => !value.isDismissed && window.open(staking_url, "_blank")
      );
  };

  const handlePopup = (info) => {
    if (info === undefined) {
      return;
    }
    swal.fire({
      width: "60%",
      heightAuto: false,
      html: (
        <>
          <div className="popup-content">
            <div>{info}</div>
            <button onClick={() => copyToClipboard(info)} className="copy-btn">
              <img className="copy-btn__img" src="/img/icon.svg" alt="copy" />
            </button>
          </div>
        </>
      ),
      showConfirmButton: false,
      showCloseButton: true,
      customClass: {
        closeButton: "popup-close",
      },
      backdrop: "rgba(74, 114, 255, 0.4)",
    });
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
  };

  return (
    <div>
      {links &&
        links.map((link, index) => (
          <ul key={index} className="row snap">
            <li className={`col ${isRpc && "col--rpc-logoname"}`}>
              <figure>
                <img src={link.logopath} alt="" />
              </figure>
              {!isRpc ? (
                <>
                  <strong>{link.name}</strong>
                  <p>{link.timestamp}</p>
                </>
              ) : (
                <span className="rpc-name">{link.name}</span>
              )}
              {!isRpc
                ? link.staking && (
                    <div className="btn-holder">
                      <a
                        onClick={(e) =>
                          handleStakingClick(
                            e,
                            link.staking_info,
                            link.staking_url
                          )
                        }
                        href={link.staking_info}
                        className="button"
                      >
                        {"Stake your tokens"}
                      </a>
                    </div>
                  )
                : link.state_sync && (
                    <div className="btn-holder">
                      <button
                        className="button button--rpc"
                        onClick={(e) => handlePopup(link.state_sync_info)}
                      >
                        {"State sync"}
                      </button>
                    </div>
                  )}
            </li>
            <li className="col col--rpc">
              {!isRpc ? (
                <>
                  <div className="tb">
                    <div className="td">
                      <button
                        className="button button--popup"
                        onClick={(e) => handlePopup(link.wget)}
                      >
                        WGET
                      </button>
                    </div>
                    <div className="td">
                      <button
                        className="button button--popup"
                        onClick={(e) => handlePopup(link.torrent)}
                      >
                        TORRENT
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="tb">
                  <div className="td td--rpc">
                    <p>{link.link_rpc}</p>
                    <button
                      onClick={() => copyToClipboard(link.link_rpc)}
                      className="copy-btn"
                    >
                      <img
                        className="copy-btn__img"
                        src="/img/icon.svg"
                        alt="copy"
                      />
                    </button>
                  </div>
                </div>
              )}
            </li>
          </ul>
        ))}
    </div>
  );
}

export default Tab;
