import React, { useEffect, useState } from "react";
import SnapshotsTab from "../components/SnapshotsTab";
import TabNav from "../components/TabNav";
import { useSnapshots } from "../hooks/useSnapshots";

function Snapshots() {
  const [activeTab, setActiveTab] = useState(null);
  const [tabs, setTabs] = useState([]);

  const { snapshots } = useSnapshots();

  useEffect(() => {
    if (snapshots) {
      const tabs = Object.keys(snapshots);
      setTabs(tabs);
      setActiveTab(tabs[0]);
    }
  }, [snapshots]);

  const handleTabChange = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab);
  };

  return (
    <>
      {snapshots && (
        <>
          <h1>SNAPSHOTS</h1>
          <div id="tabs">
            <TabNav
              data={snapshots}
              tabs={tabs}
              activeTab={activeTab}
              onTabChange={handleTabChange}
            />

            {activeTab && (
              <SnapshotsTab
                links={snapshots[activeTab]}
                index={Object.keys(snapshots).indexOf(activeTab)}
                tabName={activeTab}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}

export default Snapshots;
