import React, { useState } from "react";
import { useRecaptcha } from "../hooks/useRecaptcha";
import { RotatingLines } from "react-loader-spinner";

function FaucetsTab({ inputData, onChange, tabName, links, fauParams }) {
  const [msg, setMsg] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const executeCaptcha = useRecaptcha({
    sitekey: "6LfyASgfAAAAAGFD1MrpS4S_mP_W7TC-QUjrv2f1",
  });

  const handleFaucetClick = (name, ind) => {
    const formBlock = document.querySelector(`#form-block-${tabName}-${ind}`);
    const formBlocks = document.querySelectorAll(".form-block");
    formBlocks.forEach((block) => {
      if (formBlock !== block) block.classList.remove("show");
    });
    formBlock.classList.toggle("show");
    setMsg(null);
  };

  const showMsgAndAnimate = (hasError, message, ind) => {
    setMsg({ class: hasError ? "msg error" : "msg", message });
    setTimeout(() => {
      const msg = document.querySelector(`#msg-${ind}`);
      msg.classList.add("animated");
      setTimeout(() => {
        msg.classList.remove("animated");
        setMsg(null);
      }, 3100);
    }, 100);
  };

  const showResponseMessage = (data, tabName, linkName, ind) => {
    if (tabName.toLowerCase() === "ethereum") {
      const message = data.message;
      if (message === "Check your address") {
        showMsgAndAnimate(true, message, ind);
        return;
      } else if (message === "Failed captcha verification") {
        showMsgAndAnimate(true, message, ind);
        return;
      } else if (message === "Already funded") {
        showMsgAndAnimate(true, "Already funded in 24H", ind);
        return;
      } else if (message === "Not found") {
        showMsgAndAnimate(true, "Address not found", ind);
        return;
      }
      if (!data || data.hasOwnProperty("transactionHash")) {
        showMsgAndAnimate(false, "Successfully funded", ind);
        onChange(tabName, linkName, "");
        return;
      }
    }

    if (tabName.toLowerCase() === "cosmos") {
      if (
        data.hasOwnProperty("error") &&
        data.error.includes("account has reached to the max. allowed amount")
      ) {
        showMsgAndAnimate(true, "Funding limits exceeded", ind);
        return;
      } else if (
        data.hasOwnProperty("error") &&
        (data.error.includes("decoding bech32 failed") ||
          data.error.includes("invalid Bech32 prefix"))
      ) {
        showMsgAndAnimate(true, "Check your address", ind);
        return;
      } else if (
        Object.keys(data).length === 0 ||
        (data.hasOwnProperty("transfers") && data.transfers[0].status === "ok")
      ) {
        showMsgAndAnimate(false, "Successfully funded", ind);
        onChange(tabName, linkName, "");
        return;
      } else {
        showMsgAndAnimate(true, "Error", ind);
      }
    }
  };

  const handleSubmit = async (e, linkName, endpoint, hasCaptcha, ind) => {
    e.preventDefault();
    setLoading(true);
    let reqBody;
    let addressProp;

    if (tabName.toLowerCase() === "cosmos") {
      addressProp = "address";
    } else if (tabName.toLowerCase() === "ethereum") {
      addressProp = "walletAddress";
    }

    if (hasCaptcha) {
      const captchaResponse = await executeCaptcha(
        inputData[tabName][linkName]
      );
      reqBody = {
        [addressProp]: inputData[tabName][linkName],
        captchaResponse,
      };
    } else {
      reqBody = {
        [addressProp]: inputData[tabName][linkName],
      };
    }

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      });
      const data = await response.json();
      showResponseMessage(data, tabName, linkName, ind);
    } catch (error) {
      showMsgAndAnimate(true, "Something went wrong. Try again later", ind);
    }
    setLoading(false);
  };

  const getFauParam = (name, type) => {
    const lowered = name.toLowerCase();
    const param = fauParams[lowered][type];
    if (type === "balance") {
      return Number(param).toFixed(2);
    } else {
      return param;
    }
  };

  return (
    <div>
      {links &&
        inputData &&
        links.map((link, ind) => (
          <div key={`tab-${ind}`} className="faucet-container">
            <ul
              className="row cls row-01"
              onClick={() => {
                if (!isLoading) {
                  handleFaucetClick(link.name, ind);
                }
              }}
            >
              <li className="col">
                <figure className="logo-wrapper">
                  <img src={link.logopath} alt="" />
                </figure>

                <strong className="rpc-name">{link.name}</strong>
                <p>{link.description}</p>
              </li>
              <li className="col col--faucets">
                <div className="holder">
                  {fauParams &&
                    fauParams.hasOwnProperty(link.name.toLowerCase()) && (
                      <>
                        <span className="ico-01">
                          {getFauParam(link.name, "funding")}
                        </span>
                        <span className="ico-02">
                          {getFauParam(link.name, "tx")} funded
                        </span>
                        <span className="ico-03">
                          {getFauParam(link.name, "balance")} ethers
                        </span>
                      </>
                    )}
                </div>
              </li>
            </ul>
            <div className="form-block" id={`form-block-${tabName}-${ind}`}>
              <form
                id={`form-${link.name}`}
                onSubmit={(e) =>
                  handleSubmit(e, link.name, link.endpoint, link.captcha, ind)
                }
              >
                <div className="col-01">
                  <label htmlFor={`address-${link.name}`}>Your address</label>
                  <input
                    name={`address-${link.name}`}
                    id={`address-${link.name}`}
                    type="text"
                    value={inputData[tabName][link.name]}
                    onChange={(e) => {
                      setMsg(null);
                      onChange(tabName, link.name, e.target.value);
                    }}
                  />
                  <input type="submit" value="Request" disabled={isLoading} />
                  {isLoading && (
                    <div className="spinner">
                      <RotatingLines
                        width="30"
                        strokeColor="#293e8a"
                        strokeWidth="2"
                        animationDuration="1"
                      />
                    </div>
                  )}
                  {msg && (
                    <div id={`msg-${ind}`} className={msg.class}>
                      {msg.message}
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        ))}
    </div>
  );
}

export default FaucetsTab;
