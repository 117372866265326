import * as React from "react";
import useScript from "react-script-hook";

export function useRecaptcha({ sitekey }) {
  const [recaptcha, setRecaptcha] = React.useState();

  React.useEffect(() => {
    if (isBrowser) {
      injectStyle(".grecaptcha-badge { visibility: hidden; }");
    }
  }, []);

  useScript({
    src: `https://www.google.com/recaptcha/api.js?render=${sitekey}`,
    onload: () =>
      window.grecaptcha.ready(() => {
        setRecaptcha(window.grecaptcha);
      }),
  });

  React.useEffect(() => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(() => {
        setRecaptcha(window.grecaptcha);
      });
    }
  }, []);

  return (action) => {
    return new Promise((resolve, reject) => {
      if (recaptcha) {
        resolve(recaptcha.execute(sitekey, { action }));
      } else {
        reject(new Error("Recaptcha script not available"));
      }
    });
  };
}

const isBrowser =
  typeof window !== "undefined" && typeof window.document !== "undefined";

const injectStyle = (rule) => {
  const styleEl = document.createElement("style");
  document.head.appendChild(styleEl);

  const styleSheet = styleEl.sheet;
  if (styleSheet) styleSheet.insertRule(rule, styleSheet.cssRules.length);
};
