import httpService from "./http.service";

const snapshotsEndpoint = 'snapshots_info.json'

const snapshotsService = {
  get: async () => {
    const { data } = await httpService.get(snapshotsEndpoint);
    return data;
  }
}

export default snapshotsService;

