import React from "react";

function BotsTab({ links }) {
  return (
    <div>
      {links && links.map((link, index) => (
        <ul key={index} className="row snap">
          <li className={`col`}>
            <figure>
              <img src={link.logopath} alt="" />
            </figure>
            <strong>{link.name}</strong>
            <p>{link.description}</p>
          </li>
          <li className="col">
            <div className="tb">
              <div className="td td--rpc">
                <a
                  href={link.link}
                  className="button button--popup button--withicon"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="button--withicon__img" src="/img/telegram.svg" alt="Telegram" />
                  <span>Try this bot!</span>
                </a>
              </div>
            </div>
          </li>
        </ul>
      ))}
    </div>
  );
}

export default BotsTab;
