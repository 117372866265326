import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import Faucets from "./pages/Faucets";
import Bots from "./pages/Bots";
import Snapshots from "./pages/Snapshots";
import Main from "./pages/Main";
import FaucetsProvider from "./hooks/useFaucets";
import SnapshotsProvider from "./hooks/useSnapshots";

const main = document.querySelector("#main");
const faucets = document.querySelector("#faucets");
const bots = document.querySelector("#bots");
const snapshots = document.querySelector("#snapshots");

if (faucets) {
  ReactDOM.render(
    <React.StrictMode>
      <FaucetsProvider>
        <Faucets />
      </FaucetsProvider>
    </React.StrictMode>,
    faucets
  );
}
if (bots) {
  ReactDOM.render(
    <React.StrictMode>
      <Bots />
    </React.StrictMode>,
    bots
  );
}
if (snapshots) {
  ReactDOM.render(
    <React.StrictMode>
      <SnapshotsProvider>
        <Snapshots />
      </SnapshotsProvider>
    </React.StrictMode>,
    snapshots
  );
}
if (main) {
  ReactDOM.render(
    <React.StrictMode>
      <SnapshotsProvider>
        <FaucetsProvider>
          <Main />
        </FaucetsProvider>
      </SnapshotsProvider>
    </React.StrictMode>,
    main
  );
}
