import React, { useContext, useEffect, useState } from "react";
import faucetsService from "../services/faucets.service";

const FaucetsContext = React.createContext({});

export const useFaucets = () => {
  return useContext(FaucetsContext);
};

const FaucetsProvider = ({ children }) => {
  const [faucets, setFaucets] = useState({});
  const [faucetsParams, setParams] = useState({});

  const getAllData = async () => {
    const faucets = await faucetsService.get();
    setFaucets(faucets);
    const params = await faucetsService.getParams();
    setParams(params);
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <FaucetsContext.Provider value={{ faucets, faucetsParams }}>
      {children}
    </FaucetsContext.Provider>
  );
};

export default FaucetsProvider;
