function getTimeDiff(timestamp) {
  const now = Date.now() / 1000;
  const timeDiff = now - timestamp;
  if (timeDiff < 60) {
    return "less than minute ago";
  } else if (timeDiff >= 60 && timeDiff / 60 <= 60) {
    if (Math.floor(timeDiff / 60) === 1) {
      return "1 minute ago";
    } else {
      return Math.floor(timeDiff / 60) + " minutes ago";
    }
  } else {
    if (Math.floor(timeDiff / 3600) === 1) {
      return "1 hour ago";
    } else {
      return Math.floor(timeDiff / 3600) + " hours ago";
    }
  }
}

export default getTimeDiff;
