import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import getTimeDiff from "../utils/timeDiff";
import snapshotsService from "../services/snapshots.service";

const SnapshotsContext = React.createContext({});

export const useSnapshots = () => {
  return useContext(SnapshotsContext);
};

const SnapshotsProvider = ({ children }) => {
  const [snapshots, setSnapshots] = useState({});

  const addTimeStamps = (snapshots) => {
    const tabs = Object.keys(snapshots);
    tabs.forEach((tab) => {
      snapshots[tab].forEach((link) => {
        if (link.configpath) {
          const dataWithTimeStamp = axios.get(link.configpath);
          dataWithTimeStamp.then(({ data }) => {
            link.timestamp = getTimeDiff(data.timestamp);
            setSnapshots({ ...snapshots });
          });
        }
      });
    });
  };

  const getAllData = async () => {
    const snapshotsData = await snapshotsService.get();
    addTimeStamps(snapshotsData);
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <SnapshotsContext.Provider value={{ snapshots }}>
      {children}
    </SnapshotsContext.Provider>
  );
};

export default SnapshotsProvider;
