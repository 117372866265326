import httpService from "./http.service";

const botsEndpoint = 'bots_info.json'

const botsService = {
  get: async () => {
    const { data } = await httpService.get(botsEndpoint);
    return data;
  }
}

export default botsService;

