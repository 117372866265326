import httpService from "./http.service";

const faucetsEndpoint = 'faucets_info.json'
const paramsEndpoint = 'faucets_params.json'

const faucetsService = {
  get: async () => {
    const { data } = await httpService.get(faucetsEndpoint);
    return data;
  },
  getParams: async () => {
    const {data} = await httpService.get(paramsEndpoint);
    return data;
  }
}

export default faucetsService;

